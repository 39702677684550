import React from 'react'

const IconDashboard = ({ fill = '#FFFFFF', height = 26, width = 30 }) => (
    <svg width={width} height={height} viewBox="0 0 30 26" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-33, -119)" fill={fill} fillRule="nonzero">
                <g transform="translate(33, 119)">
                    <path d="M30,14.5359729 L15.813964,0.348580325 C15.6017242,0.125970587 15.3075725,0 15,0 C14.6924275,0 14.3982758,0.125970587 14.186036,0.348580325 L0,14.5359729 L1.62792801,16.1639009 L4.53513611,13.2566928 L4.53513611,24.4188526 C4.53736886,25.0600183 5.05658231,25.5792317 5.69774803,25.5814645 L24.3036086,25.5814645 C24.9447743,25.5792317 25.4639877,25.0600183 25.4662205,24.4188526 L25.4662205,13.2566928 L28.372072,16.1639009 L30,14.5359729 Z M23.13964,23.2562406 L6.86035995,23.2562406 L6.86035995,10.931469 L15,2.79182895 L23.13964,10.931469 L23.13964,23.2575972 L23.13964,23.2562406 Z"></path>
                </g>
            </g>
        </g>
    </svg>
);

export default IconDashboard;