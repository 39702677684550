import React from 'react'

function IconImageAltTextGenerator({ fill = 'black', height = 30, width = 30 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 27.5L11.6875 22.5H5C4.3125 22.5 3.72396 22.2552 3.23437 21.7656C2.74479 21.276 2.5 20.6875 2.5 20V5C2.5 4.3125 2.74479 3.72396 3.23437 3.23437C3.72396 2.74479 4.3125 2.5 5 2.5H25C25.6875 2.5 26.276 2.74479 26.7656 3.23437C27.2552 3.72396 27.5 4.3125 27.5 5V20C27.5 20.6875 27.2552 21.276 26.7656 21.7656C26.276 22.2552 25.6875 22.5 25 22.5H18.3125L15 27.5ZM5 20H25V5H5V20Z" fill={fill} />
      <path d="M8 15.7354V13.353H22.47V15.7354H8Z" fill={fill} />
      <path d="M8 11.3824V9H22.47V11.3824H8Z" fill={fill} />
    </svg>

  )
}

export default IconImageAltTextGenerator