import React from 'react'

function IconProductDescriptionWriter({ fill = 'black', height = 23, width = 23 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.04167 22.375C2.37708 22.375 1.80816 22.1384 1.3349 21.6651C0.861632 21.1918 0.625 20.6229 0.625 19.9583V3.04167C0.625 2.37708 0.861632 1.80816 1.3349 1.3349C1.80816 0.861632 2.37708 0.625 3.04167 0.625H19.9583C20.6229 0.625 21.1918 0.861632 21.6651 1.3349C22.1384 1.80816 22.375 2.37708 22.375 3.04167V19.9583C22.375 20.6229 22.1384 21.1918 21.6651 21.6651C21.1918 22.1384 20.6229 22.375 19.9583 22.375H3.04167ZM3.04167 19.9583H19.9583V5.45833H3.04167V19.9583ZM5.45833 11.5V9.08333H17.5417V11.5H5.45833ZM5.45833 16.3333V13.9167H12.7083V16.3333H5.45833Z" fill={fill} />
    </svg>
  )
}

export default IconProductDescriptionWriter