import React from 'react'

function IconArticleWriter({ fill = 'black', height = 24, width = 27 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0V2.38235H17.4706V0H0ZM0 8.73529V6.35294H17.4706V8.73529H0ZM0 15.0882V12.7059H11.1176V15.0882H0ZM13.6834 23.8235V19.627L22.3056 11.0442C22.503 10.8467 22.7163 10.7077 22.9452 10.6273C23.1742 10.5471 23.4032 10.507 23.6321 10.507C23.8818 10.507 24.1233 10.5537 24.3568 10.6471C24.5903 10.7408 24.8024 10.8814 24.9933 11.0688L26.4624 12.553C26.6334 12.7507 26.7657 12.9646 26.8594 13.1947C26.9531 13.4247 27 13.6547 27 13.8848C27 14.1148 26.9573 14.3489 26.8717 14.5871C26.7862 14.8254 26.6498 15.0432 26.4624 15.2407L17.88 23.8235H13.6834ZM15.577 21.93H17.0858L22.2412 16.7495L22.2411 16.7494L20.7721 15.2502L15.577 20.4211V21.93Z" fill={fill} />
    </svg>
  )
}

export default IconArticleWriter